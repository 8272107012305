const REFRESH_CURRENT_PATH = 'routing/REFRESH_CURRENT_PATH';

export const refreshCurrentPath = () => ({
  type: REFRESH_CURRENT_PATH,
});

const initialState = {
  currentPath: window.location.pathname,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REFRESH_CURRENT_PATH:
      return {
        currentPath: window.location.pathname,
      };
    default:
      return state;
  }
};
