/* eslint import/prefer-default-export: off */
import {
  setShowKycBanner,
  setShowNdlChange,
  loadCustomerConsentMessages,
  loadRestrictions,
  loadKycStrategiesV2,
  loadDepositLimitInfo,
  closeDepositLimitConfirmation,
} from './ducks/session';
import { setCustomerConsentMessages } from './thunks/session';

const session = {
  setShowKycBanner,
  setShowNdlChange,
  setCustomerConsentMessages,
  loadCustomerConsentMessages,
  loadRestrictions,
  loadKycStrategiesV2,
  loadDepositLimitInfo,
  closeDepositLimitConfirmation,
};

export {
  session,
};
