const SET_VERSION = 'app/SET_VERSION';

export const setVersion = (version) => ({
  type: SET_VERSION,
  version,
});

const initialState = {
  version: window.BVVars.version,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_VERSION:
      return {
        version: action.version,
      };
    default:
      return state;
  }
};
