/* eslint import/prefer-default-export: off */
import { createSelector } from 'reselect';
import { isEqual } from 'underscore';
import { v as bvVar } from 'bv';
import { homeProduct } from 'global-config';
import { withoutTrailingSlash } from '../../helpers/url';

// ========================================
// Get current path without locale selector
// ========================================
export const getCurrentPath = (state) => state.routing.currentPath;

export const getCurrentPathWithoutLocale = createSelector(
  [getCurrentPath],
  (currentPath) => withoutTrailingSlash(currentPath.replace(/^\/[a-z]{2}[-_][a-z]{2}/i, '')),
);

// ===================================================
// Get href, path to check received as second argument
// ===================================================
const getHref = (state, href) => withoutTrailingSlash(href);

// ============================================================
// Get URL groups, groups of URLs that display the same content
// ============================================================
const HOME_PRODUCT_MAPPING = {
  sports: () => '/sports',
  casino: (tag) => {
    const lobby = bvVar('casinoLobbies').find(({ tag: lobbyTag }) => lobbyTag === tag);

    return lobby ? `/${lobby.slug}` : '';
  },
};

const getHomeSlug = () => {
  const [productName, tag] = homeProduct.split('::');

  return HOME_PRODUCT_MAPPING[productName](tag);
};

const knownUrlGroups = [
  [getHomeSlug(), ''],
];

const getCasinoFirstCategory = (state) => (
  state.casinoStore
  && state.casinoStore.categories
  && state.casinoStore.categories[0]
);

const getCasinoGroups = createSelector(
  [getCasinoFirstCategory],
  (category) => (
    category
      ? [['/casino', `/casino/category/${category.url}`]]
      : []
  ),
);

const getUrlGroups = createSelector(
  [getCasinoGroups],
  (casinoGroups) => [...casinoGroups, ...knownUrlGroups],
);

// ===================
// Comparison methods
// ===================
const currentIsChild = (currentPath, href) => (
  href !== '' && currentPath.startsWith(href.replace(/\/?$/, '/'))
);

const inSameGroup = (currentPath, href, urlGroups) => (
  urlGroups.some((group) => group.includes(currentPath) && group.includes(href))
);

const comparisonMethods = [
  isEqual,
  currentIsChild,
  inSameGroup,
];

// ==================
// Exported selectors
// ==================
export const makeIsCurrentPath = () => createSelector(
  [getCurrentPathWithoutLocale, getHref, getUrlGroups],
  (...args) => comparisonMethods.some((method) => method(...args)),
);
