import { getJSON } from '../../fetch';
import { loadCustomerConsentMessages } from '../ducks/session';

export const setCustomerConsentMessages = () => (dispatch, getState) => {
  const { customerConsentMessages } = getState().session;

  if (customerConsentMessages.length) return;

  getJSON('/bv_api/security_checks/customer_content_messages')
    .then((response) => {
      dispatch(loadCustomerConsentMessages(response));
    });
};
