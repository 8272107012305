require('expose-loader?reactRedux!react-redux');
require('expose-loader?reduxThunk!redux-thunk');
require('expose-loader?reselect!reselect');
require('expose-loader?normalizr!normalizr');

require('expose-loader?reduxDynamicMiddlewares!../src/utils/redux/lib/redux-dynamic-middlewares');
require('expose-loader?reduxSelectors!../src/utils/redux/selectors');
require('expose-loader?reduxActionTypes!../src/utils/redux/action_types');

require('../src/utils/redux');
